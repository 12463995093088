@import 'lib/reset';

* {
    -webkit-font-smoothing: antialiased !important;
    text-rendering: optimizeLegibility !important;
    font-family: 'open sans', 'proxima_nova', 'helvetica neue', 'arial', sans-serif !important;
    transition: none !important;
}

$color-grey: #aaa;
$color-info: #99aaf8;
$color-error: #FE3E00;
$input_border: 0.5px solid $color-grey;

$breakpoint-full: 480px;

html, body {
    height: 100%;
    overflow: hidden;
}

.auth0-lock-widget {
    width: 100% !important;
}

.auth0-lock-name {
    display: none !important;
}

@media (min-width: $breakpoint-full) {
    body {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 0px; padding: 0px;
        background-color: #d9ddf4;

        &::before {
            z-index: 0;
            transform: rotate(12deg);
            position: absolute;
            top: -2000px; left: -2000px; right: -2000px; bottom: -2000px;
            pointer-events: none;
            content: '';
            opacity: 0.25;
            background-color: white;
            background-image: url("./42-logo-black-small.png");
            background-repeat: repeat;
            background-size: 22px;
        }
    }
    #container {
        width: 400px;
    }
}
    #container {
        background: lighten($color-info, 20.5%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 1;
        padding: 10px 40px;
        border-radius: 10px;
        border: 0.5px solid black;
        box-shadow:
            0px 15px 5px -10px rgba($color-info, 0.3),
            0px 15px 16px -10px rgba(black, 0.3);
        overflow: visible;

        #login-form {
            width: 100%;
            padding: 0px 5px;
            height: 100%;
            overflow: visible;
        }

        footer {
            padding: 20px;
            width: 100%;
            justify-self: flex-end;
        }
    }

    #login-form {
        overflow: hidden;

        .auth0-lock-center,
        .auth0-lock-widget,
        .auth0-lock-widget-container {
            padding: 0px;
        }
        .auth0-lock-form {
            padding: 0px;
            padding-top: 25px;
        }
        .auth0-lock-view-content {
            width: 100%;
            margin: 0 auto;
        }
        .auth0-lock-content {
            padding-bottom: 60px !important;
        }

        // .auth0-lock.auth0-lock .auth0-lock-content-wrapper {
        //     overflow: visible !important;
        // }
        .auth0-lock.auth0-lock .auth0-lock-header {
            overflow: visible !important;
            margin-top: 15px;
            background: none;
            user-select: none;

            .auth0-lock-back-button {
                background: transparent;
                box-shadow: none !important;
                border: solid transparent 10px;
                top: 20px;
                height: 15px !important;
                width: 15px !important;
                &:hover {
                    background: #eee;
                }
            }
            .auth0-lock-header-bg {
                background: none;
                display: none;
                box-shadow: none;
            }
            .auth0-lock-header-logo {
                background: none;
                $size: 64px;
                height: $size;
                width: $size;
                margin: 0px;
                padding: 10px;
                color: #fcfcfa;
                margin-bottom: 0px;
                border-radius: 12px;
            }
        }

        .auth0-global-message-error {
            border-radius: 5px;
        }

        .auth0-sso-notice-container {
            user-select: none;
            border-radius: 5px;
            background: rgba($color-info, 0.1);
            border: 0.5px solid rgba($color-info, 0.5);
            color: darken($color-info, 6%);
            line-height: 1.6;
            width: auto;
            margin: 0px;
            margin-bottom: 20px;
            * {
                fill: darken($color-info, 10%);
            }
        }

        .auth0-lock-input-block {
            padding: 0px;
            .auth0-lock-error-invalid-hint {
                color: $color-error;
                display: none;
            }
            &.auth0-lock-error .auth0-lock-input-wrap {
                border-color: $color-error;
                * {
                    fill: red;
                }
                input::placeholder {
                    opacity: 0.6;
                    color: red;
                }
                box-shadow: 0px 0px 0px 2px rgba(red, 0.1);
            }
            .auth0-lock-input-wrap {
                background: transparent;
                border-radius: 6px;
                border-width: 0.5px;
                border-style: solid;
                border-color: #444;
                &.auth0-lock-focused {
                    border-color: black;
                    box-shadow: 0px 0px 0px 3px rgba($color-info, 0.5);
                }
                &.auth0-lock-input-wrap-with-icon input {
                    border: none;
                    padding-left: 0px;
                    border-radius: 0px 5px 5px 0px;
                }
            }

            .auth0-lock-input {
                background: transparent;
                border-radius: 5px;
                border: none;
            }
        }

        .auth0-lock-alternative-link {
            font-style: italic;
            opacity: 0.8;
            border-bottom: 1px solid rgba(black, 0.2);
            text-decoration: none !important;
            position: relative;
            top: 5px;

            &:hover {
                opacity: 1;
                border-bottom-color: rgba(black, 0.5);
            }
        }
        .auth0-lock-quiet, .auth0-lock-moving {
            & .auth0-label-submit {
                transition: all 0s;
            }
        }
        .auth0-label-submit {
            span {
                position: relative;
                top: 1px;
                right: -5px;
            }
        }

        .auth0-lock-cred-pane {
            background: transparent;
            border-radius: 0px;
            .auth0-lock-body-content {
                display: block;
                opacity: 1;
                visibility: visible;
            }
        }
        .auth0-lock-submit {
            border-radius: 5px;
            transition: all 0s;
            &:hover {
                transition: all 0s;
                background-color: lighten(#242A33, 5%) !important;
            }
            & > * {
                transition: all 0s;
                transform: none;
            }
        }
    }


    .support {
        margin-top: 20px;
        font-size: 12px;
        text-align: center;
        opacity: 0.7;
        span {
            text-align: center;
            display: block;
            margin-bottom: 10px;
            opacity: 0.8;
            user-select: none;
        }
        a {
            display: inline-block;
            border-bottom: 1px solid rgba(black, 0.1);
            text-decoration: none;
            color: black;
            font-style: italic;
            &:hover {
                border-bottom-color: rgba(black, 0.6);
            }
        }
    }

@media (max-width: $breakpoint-full) {
    #container {
        display: block !important;
        position: absolute !important;
        top: 0px; left: 0px; bottom: 100px !important; right: 0px;
        box-shadow: none;
        border-radius: 0px;
        border: none;
        width: auto !important;
        overflow: visible !important;
        padding: 0px 40px !important;
        padding-bottom: 0px;
        height: 100% !important;

        .auth0-lock-content-wrapper {
            overflow: visible !important;
        }

        #login-form {
            position: relative !important;
            overflow: visible !important;
            padding: 0; margin: 0;
        }
        #login-form .auth0-lock {
            width: auto;
            overflow: visible !important;
            position: absolute;
        }
        .auth0-lock-submit {
            margin-bottom: 20px !important;
        }
        .footer {
            display: none;
        }
    }


}
@media (max-height: 550px) {
    .auth0-lock-header-logo {
        display: none !important;
    }
}
// @media (max-height: 440px) {
//     .support {
//         display: none !important;
//     }
// }

// @media (min-width: $breakpoint-full) {
// //     #container {
//         height: auto;
//         padding: 30px;
//         border-radius: 10px;
//         border: 0.5px solid black;
//         box-shadow: 0px 1px 0px 3px rgba(black, 0.05);
//     }
//     // .auth0-lock-cred-pane {
//     //     &:after {
//     //         position: absolute;
//     //         top: 0;
//     //         left: 0;
//     //         bottom: 0;
//     //         right: 0;
//     //         content: '';
//     //         display: block;
//     //         z-index: 9;
//     //         pointer-events: none;
//     //     }
//     // }
//     .auth0-lock-submit {
//         margin: 0 auto !important;
//         z-index: 99;
//         position: relative;
//     }
    // .auth0-lock-content {
    //     padding-bottom: 60px !important;
    // }
//     .auth0-lock-quiet .auth0-lock-submit {
//         opacity: 1;
//         transition: opacity 0.1s ease-in !important;
//     }
//     .auth0-lock-moving .auth0-lock-submit {
//         opacity: 0;
//         transition: all 0s !important;
//         display: block !important;
//     }
// }


// }

// @media (max-height: 495px) {
//     body,
//     html,
//     #container, #login-form {
//         height: 100% !important;
//         width: 100% !important;
//         margin: 0px; padding: 0px;
//     }
//     .auth0-lock-header {
//         height: 0px !important;
//         overflow: visible !important;
//     }
//     .auth0-lock-content {
//         padding-bottom: 10px !important;
//     }
//     .auth0-lock-back-button {
//         left: -60px !important;
//     }
//     .auth-lock-header {
//         padding: 0px !important;
//         margin: 0px !important;
//         height: 0px !important;
//     }
//     .auth0-lock-header-welcome {
//         display: none;
//     }
//     .auth0-lock-content {
//         margin-top: 0 !important;
//         padding: 0 !important;
//     }
//     .auth0-lock-submit {
//         width: 100% !important;
//         border-radius: 0px !important;
//         position: absolute;
//         bottom: 0;
//     }
//     .support {
//         display: none;
//     }
// }

// @media (max-width: 320px) {
//     body {
//         background: white;
//     }
//     .auth0-lock-input-wrap-with-icon {
//         padding-left: 0 !important;
//     }
//     .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-icon.auth0-lock-icon-box {
//         display: none !important;
//     }
//     .support {
//         display: none;
//     }
// }

// @media (max-width: 500px) {
//     #container {
//         border: none;
//         box-shadow: none;
//         width: 100%;
//         padding: 0;
//         height: 100% !important;
//     }
//     .auth0-lock-input {
//         height: 80px !important;
//     }
//     .auth0-lock-input-block {
//         input {
//             font-size: 16px !important;
//         }
//     }
//     .auth0-lock-content {
//         margin: 0 30px;
//         padding: 0px !important;
//         transform: translate(0, 20px);
//     }
//     .auth0-lock-view-content {
//         width: 100% !important;
//     }
//     .auth0-lock-submit {
//         width: 100% !important;
//         border-radius: 0px !important;
//         position: absolute;
//         bottom: 0;
//     }
//     .auth0-lock-input-show-password .auth0-lock-show-password {
//         position: absolute;
//         top: 34px !important;
//         height: 28px !important;
//     }
//     .auth0-lock.auth0-lock .auth0-lock-input-wrap .auth0-lock-icon.auth0-lock-icon-box {
//         position: absolute;
//         top: 24px !important;
//         height: 28px !important;
//     }
//     .support {
//         display: none;
//     }
// }
